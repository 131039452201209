// styled components
import { Container, MenuModal } from "./style";

// components
import { motion } from "framer-motion";
import ModalWindow from "@components/ModalWindow";
import {
  ScaleControl,
  ContrastControl,
  ThemeControl,
  DirectionControl,
} from "@components/GlobalSettingsControls";

// hooks
import { useLocation, useNavigate } from "react-router-dom";
import { useSidebarContext } from "@contexts/sidebarContext";
import React, { useEffect, useState } from "react";
import { menuItems } from "@constants/menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ScienceIcon from "@mui/icons-material/Science";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import MedicationIcon from "@mui/icons-material/Medication";
import HelpIcon from "@mui/icons-material/Help";
import ChatIcon from "@mui/icons-material/Chat";
import CampaignIcon from "@mui/icons-material/Campaign";

const iconMap = {
  DashboardIcon: <DashboardIcon fontSize="small" />,
  CloudUploadIcon: <CloudUploadIcon fontSize="small" />,
  ScienceIcon: <ScienceIcon fontSize="small" />,
  FitnessCenterIcon: <FitnessCenterIcon fontSize="small" />,
  MedicationIcon: <MedicationIcon fontSize="small" />,
  HelpIcon: <HelpIcon fontSize="small" />,
  ChatIcon: <ChatIcon fontSize="small" />,
  CampaignIcon: <CampaignIcon fontSize="small" />,
};

const BottomMenu = () => {
  const [open, setOpen] = useState(false);
  const { isSidebarOpen } = useSidebarContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState("");

  //   const buttons = [
  //     {
  //       label: "Emergency call",
  //       icon: "microphone-light",
  //     },
  //     {
  //       label: "Emergency call",
  //       icon: "microphone-light",
  //     },
  //     {
  //       label: "Emergency call",
  //       icon: "microphone-light",
  //     },
  //     {
  //       label: "Profile",
  //       icon: "user-light",
  //     },
  //     {
  //       label: "Home",
  //       icon: "house-solid",
  //       onClick: () => navigate("/"),
  //     },
  //     {
  //       label: "Notifications",
  //       icon: "bell-light",
  //     },
  //     {
  //       label: "Accessibility settings",
  //       icon: "gear-light",
  //       onClick: () => setOpen(true),
  //     },
  //   ];

  const renderIcon = (iconName, isActive) => {
    const IconComponent = iconMap[iconName];
    return (
      IconComponent &&
      React.cloneElement(IconComponent, {
        fontSize: "small",
        style: { color: isActive ? "#007BFF" : "#ffffff" }, // Change icon color based on active state
      })
    );
  };

  useEffect(() => {
    const currentItem = menuItems.find(
      (item) => location.pathname === item.path
    );
    if (currentItem) {
      setActiveMenu(currentItem.name); // Use 'name' instead of 'label'
    }
  }, [location.pathname]);

  const handleMenuClick = (name, onClick, path) => {
    setActiveMenu(name); // Update active state
    if (path) {
      navigate(path); // Navigate if a path is provided
    }
    if (onClick) {
      onClick(); // Execute the onClick handler if provided
    }
  };

  console.log("==> :ISACTIVE: ", activeMenu);

  return (
    <Container
      as={motion.div}
      initial={{ y: "100%" }}
      animate={{ y: isSidebarOpen ? "100%" : 0 }}
      transition={{ duration: 0.3, ease: "linear", type: "tween" }}
    >
      {menuItems?.map((btn) => {
        const { name, icon, onClick, link } = btn;
        const isActive = activeMenu === name;

        return (
          <button
            key={name}
            aria-label={name}
            onClick={() => handleMenuClick(name, onClick, link)}
            className={`menu-item ${isActive ? "active" : ""}`}
            style={{
              backgroundColor: isActive ? "#f0f0f0" : "transparent", // Change background color for active
              border: isActive ? "2px solid #007BFF" : "2px solid transparent", // Add border when active
              borderRadius: "8px", // Example border radius
              padding: "8px", // Example padding
            }}
          >
            {renderIcon(icon, isActive)}
          </button>
        );
      })}
      <ModalWindow isVisible={open} visibilityHandler={setOpen}>
        <MenuModal style={{ gap: 20 }}>
          <DirectionControl />
          <ThemeControl />
          <ScaleControl />
          <ContrastControl />
        </MenuModal>
      </ModalWindow>
    </Container>
  );
};

export default BottomMenu;
