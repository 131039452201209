// styled components
import { LinksList, List, MainItem } from "../style";
import { colors } from "@styles/vars";

// components
import Accordion from "react-bootstrap/Accordion";
import { NavLink } from "react-router-dom";

// hooks
import { useSidebarContext } from "@contexts/sidebarContext";

// menu links
import { menuItems } from "@constants/menu";
import { getUserType } from "@store/selectors";
import { useSelector } from "react-redux";

import DashboardIcon from "@mui/icons-material/Dashboard";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ScienceIcon from "@mui/icons-material/Science";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import MedicationIcon from "@mui/icons-material/Medication";
import HelpIcon from "@mui/icons-material/Help";
import ChatIcon from "@mui/icons-material/Chat";
import CampaignIcon from "@mui/icons-material/Campaign";

const iconMap = {
  DashboardIcon: <DashboardIcon fontSize="small" />,
  CloudUploadIcon: <CloudUploadIcon fontSize="small" />,
  ScienceIcon: <ScienceIcon fontSize="small" />,
  FitnessCenterIcon: <FitnessCenterIcon fontSize="small" />,
  MedicationIcon: <MedicationIcon fontSize="small" />,
  HelpIcon: <HelpIcon fontSize="small" />,
  ChatIcon: <ChatIcon fontSize="small" />,
  CampaignIcon: <CampaignIcon fontSize="small" />,
};

const Content = () => {
  const { toggleSidebar } = useSidebarContext();
  const activeStyle = { color: colors.blue };

  const userType = useSelector(getUserType);

  const renderIcon = (iconName) => iconMap[iconName] || null;

  return (
    <List as={Accordion}>
      {menuItems?.map((item, index) => {
        if (item?.cat) {
          return (
            <Accordion.Item eventKey={item.cat} key={item.cat}>
              <MainItem as={Accordion.Header}>
                <i className={`icon icon-${item.icon}`}></i> {item.cat}
              </MainItem>
              <Accordion.Body>
                <LinksList>
                  {item.links.map((link) => {
                    return (
                      <li key={link.link}>
                        <NavLink
                          to={link.link}
                          onClick={() => toggleSidebar()}
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          {link.name}
                        </NavLink>
                      </li>
                    );
                  })}
                </LinksList>
              </Accordion.Body>
            </Accordion.Item>
          );
        } else if (item?.link) {
          if (item.name === "Advertisement" && userType !== "SuperAdmin") {
            return null;
          }

          return (
            <MainItem
              as={NavLink}
              to={
                item.link === "/chat"
                  ? userType === "SuperAdmin"
                    ? "/doctor_messenger"
                    : "/chat"
                  : item.link
              }
              onClick={() => toggleSidebar()}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              key={item.link}
              // className={index === menu.length - 1 ? "pin-down" : ""}
            >
              {renderIcon(item.icon)}
              {item.name}
              {/* <i className={`icon icon-${item.icon}`}></i>  */}
            </MainItem>
          );
        } else return null;
      })}
    </List>
  );
};

export default Content;
